import { ReactElement, useCallback, useMemo, useEffect, memo, useRef } from "react";
import { FormLabel, styled, Typography, Box } from "@mui/material";
import { useForm, SubmitHandler, Controller, Control } from "react-hook-form";
import { SELECTED_INTERVAL_LABELS, SLOT_INTERVAL_LABELS } from "../../../constants";
import {Meeting, User } from "../../../store";
import { CabButton, CabDropdown, CabIcon, CabSwitch, CabTooltip } from "@CabComponents";
import { DependentController, useAutoSubmit } from "../../../utils/formUtils";
import { isEqual } from "lodash-es";
import { CabDurationUnitPicker } from "@CabComponents/CabDurationUnitPicker";
import { useDebouncedCallback } from "use-debounce";
import { IoWarningOutline, IoOpenOutline, IoLockClosedOutline } from "react-icons/io5";
import InfoToolTip from "../../Common/InfoToolTip";
import { AnalyticsEventColor } from "../../../store/cabinetApi/generated/analytics";
import UserPrefCabDatePicker from "@CabComponents/CabDatePicker/UserPrefDatePicker";

export interface BookingOptionsFormInput {
  selectedCalendar?: number;
  selectedColor?: AnalyticsEventColor;
  preventDoubleBookings?: boolean;
  anonymizeVoterNames?: boolean;
  allowAddParticipants?: boolean;
  allowRescheduleCancel?: boolean;
  sendParticipantReminders?: boolean;
  participantReminderMinutes?: number;
  startDelay: number;
  slotInterval: number;
  enablePublicAttendeeList?: boolean;
  bufferStartMinutes: number;
  bufferEndMinutes: number;
}

export interface BookingOptionsProps {
  meeting: Meeting;
  user: User | null | undefined;
  onChangeValues: (meetingPartial: Partial<Meeting>) => Promise<void>;
  openUpgradeModal: () => void;
  mergeSlots: boolean;
  onToggleMergeSlots: () => void;
  showTimesWithLinks: boolean;
  onToggleShowTimesWithLinks: () => void;
  setOpenPreview: (value: boolean) => void;
  onAllowVotersToSelectFromList: (allow: boolean) => Promise<void>;
  hasAttendeeListOptions: boolean;
  isOneColumn: boolean;
  setEndDate?: (date: string) => void;
  endDate?: string;
}

const BookingOptionsPanel = ({
  meeting, user, onChangeValues, openUpgradeModal, mergeSlots, onToggleMergeSlots, showTimesWithLinks, 
  onToggleShowTimesWithLinks, setOpenPreview, onAllowVotersToSelectFromList, hasAttendeeListOptions, isOneColumn,
  endDate, setEndDate
}: BookingOptionsProps): ReactElement => {
  const formRef = useRef<HTMLDivElement>(null);

  const defaultValues: BookingOptionsFormInput = useMemo(() => {
    return {
      selectedCalendar: meeting.booking_calendar,
      preventDoubleBookings: meeting.prevent_conflict,
      anonymizeVoterNames: !meeting.show_voter_names,
      enablePublicAttendeeList: meeting.enable_public_attendee_list,
      allowAddParticipants: meeting.allow_add_participants,
      allowRescheduleCancel: meeting.allow_reschedule_cancel,
      startDelay: meeting.start_delay_minutes,
      slotInterval: meeting.slot_time_interval || 15,
      bufferStartMinutes: meeting.buffer_start_minutes,
      bufferEndMinutes: meeting.buffer_end_minutes,
      sendParticipantReminders: meeting.send_participant_reminders,
      participantReminderMinutes: meeting.participant_reminder_minutes,
    };
  }, [meeting.booking_calendar, meeting.prevent_conflict, meeting.show_voter_names, meeting.enable_public_attendee_list,
    meeting.allow_add_participants, meeting.allow_reschedule_cancel, meeting.start_delay_minutes, 
    meeting.slot_time_interval, meeting.buffer_start_minutes, meeting.buffer_end_minutes,
    meeting.send_participant_reminders, meeting.participant_reminder_minutes]);

  const bookingForm = useForm<BookingOptionsFormInput>({ defaultValues, mode: 'onBlur' });
  const { control, reset, watch } = bookingForm;

  const enablePublicAttendeeList = watch('enablePublicAttendeeList');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleSubmit: SubmitHandler<BookingOptionsFormInput> = useCallback(async (data) => {
    await onChangeValues({
      booking_calendar: data.selectedCalendar,
      booking_color: data.selectedColor,
      prevent_conflict: data.preventDoubleBookings,
      show_voter_names: data.anonymizeVoterNames !== undefined ? !data.anonymizeVoterNames : undefined,
      enable_public_attendee_list: data.enablePublicAttendeeList,
      allow_add_participants: data.allowAddParticipants,
      allow_reschedule_cancel: data.allowRescheduleCancel,
      start_delay_minutes: data.startDelay,
      slot_time_interval: data.slotInterval,
      buffer_start_minutes: data.bufferStartMinutes,
      buffer_end_minutes: data.bufferEndMinutes,
      send_participant_reminders: data.sendParticipantReminders,
      participant_reminder_minutes: data.participantReminderMinutes,
    });
  }, [onChangeValues]);

  useAutoSubmit({
    control: bookingForm.control,
    memoizedHandleSubmit: handleSubmit,
    fields: ["anonymizeVoterNames", "allowAddParticipants", "allowRescheduleCancel", "enablePublicAttendeeList",
      "selectedCalendar", "preventDoubleBookings", "startDelay", "slotInterval", "bufferStartMinutes", 
      "bufferEndMinutes", "sendParticipantReminders", "participantReminderMinutes"],
    options: {
      anonymizeVoterNames: { debounce: 500 },
      enablePublicAttendeeList: { debounce: 500},
      allowAddParticipants: { debounce: 500 },
      allowRescheduleCancel: { debounce: 500 },
      startDelay: { debounce: 800 },
      bufferStartMinutes: { debounce: 800 },
      bufferEndMinutes: { debounce: 800 },
      sendParticipantReminders: { debounce: 500 },
      participantReminderMinutes: { debounce: 800 },
    },
    handleSubmit: bookingForm.handleSubmit,
    getValues: bookingForm.getValues,
    reset: bookingForm.reset
  });

  const anonymousPollsOption = meeting.is_poll && user?.features.ANONYMOUS_POLLS;
  const additionalParticipantsOption = user?.features.PREVENT_ADD_PARTICIPANTS;

  const handleAllowVotersToSelectFromList = useDebouncedCallback((allow: boolean) => {
    onAllowVotersToSelectFromList(allow);
  }, 1000);

  const allowConflictLeaders = useMemo(() => (
    meeting.leader_info ? meeting.leader_info.filter(l => !l.prevent_conflicts)
      .map(l => {
        return (l.first_name || l.last_name) ? `${l.first_name} ${l.last_name}` : l.email || '';
      }) : []), [meeting.leader_info]);

  const allowConflictParticipants = useMemo(() => (
    Object.values(meeting.participants || {}).filter(p => p.calendar_access && !p.prevent_conflicts)
      .map(p => {
        return p.name || p.email || '';
      })), [meeting.participants]);

  const externalParticipantLength = useMemo(() => (
    Object.keys(meeting.participants || {}).length
  ), [meeting.participants]);

  const participantLength = useMemo(() => (
    (meeting.leader_info?.length || 0) + externalParticipantLength 
  ), [meeting.leader_info?.length, externalParticipantLength]);

  const allowConflictAttendees = useMemo(() => (
    [...allowConflictLeaders, ...allowConflictParticipants]
  ), [allowConflictLeaders, allowConflictParticipants]);


  if (!user) return <></>;

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height={'100%'}>
      <Box display='flex' flexDirection='column' width={'100%'} gap={2} ref={formRef}>
        <Box display='flex' flexDirection='column' gap={1} width='100%'>
          <Typography variant="h2"> Availability Settings </Typography>
          <Controller name="preventDoubleBookings" control={control}
            render={({ field: { value, ref, ...field } }) => (
              <AdditionalOption>
                <Box display='flex' gap={.5} alignItems={'center'}>
                  <FormLabel>Prevent conflicts</FormLabel>
                  <InfoToolTip 
                    message={`Cabinet will automatically exclude times that conflict with “busy” calendar events ` +
                    `or buffer times from other meetings.`}
                  />
                </Box>
                <Box display='flex' alignItems='center' gap={.5}>
                  {allowConflictAttendees.length > 0 && (
                    <CabTooltip 
                      title={allowConflictAttendees.length === participantLength ? 
                        'All attendees have "Prevent conflicts" turned off for this meeting'
                        : <div>Some attendees have "Prevent conflicts" turned off for this meeting:<br />
                          {allowConflictAttendees.map(attendee => {
                            return <span key={attendee}>{`• ${attendee}`}<br /></span>;
                          })}
                        </div>}
                      wrapWithSpan
                    >
                      <CabIcon Icon={IoWarningOutline} sx={{fontSize: '20px', color: 'orange'}}/>
                    </CabTooltip>
                  )}
                  <CabSwitch
                    {...field}
                    checked={value}
                    sx={{ margin: 0 }}
                  />
                </Box>
              </AdditionalOption>
            )} 
          />
          
          <Controller name="startDelay" control={control} render={({ field: { ref, ...field } }) => (
            <AdditionalOption>
              <Box display='flex' gap={.5} alignItems={'center'}>
                <FormLabel sx={{lineHeight: 1}}>Minimum booking notice </FormLabel>
                <InfoToolTip 
                  message='This will prevent attendees from choosing times within the selected time 
                window. This helps avoid last-minute events from appearing on the calendar.'
                />
              </Box>
              <Box display='flex' alignItems='center' 
                onClick={!user?.features.ADVANCE_NOTICE ? openUpgradeModal : undefined}
              >
                {!user?.features.ADVANCE_NOTICE  && (
                  <CabIcon
                    Icon={IoLockClosedOutline}
                    alt='Locked'
                    slot='end'
                    sx={{ fontSize: 25, marginRight: 1 }}
                  />
                )}
                <CabDurationUnitPicker
                  {...field}
                  durationMinutes={field.value}
                  disabled={!user?.features.ADVANCE_NOTICE}
                  textSize
                />
              </Box>
            </AdditionalOption>
          )} />
          <Controller name="slotInterval" control={control}
            render={({ field: { value, ref, ...field } }) => (
              <AdditionalOption>
                <Box display='flex' gap={.5} alignItems={'center'}>
                  <FormLabel>Frequency of meeting slots</FormLabel>
                  <InfoToolTip 
                    message="Make sure meetings start only at the interval you prefer. For example, 
                  “Every 30 minutes” will show times like 1:00 and 1:30, but NOT 1:45."
                  />
                </Box>
                <CabTooltip 
                  title={!meeting.auto_merge_slots ? 'Exact times is turned on so this setting has no effect. Times ' + 
                  'will be shown to attendees exactly as they are selected.' : ''} 
                  wrapWithSpan
                >
                  <CabDropdown<number>
                    {...field}
                    overrides={{renderValue: (selected) => {
                      return SELECTED_INTERVAL_LABELS[Number(selected)];
                    }}}
                    size='xsmall'
                    value={value}
                    sx={{width: '140px'}}
                    disabled={!meeting.auto_merge_slots}
                    options={Object.keys(SLOT_INTERVAL_LABELS).filter(i => i !== '0').map((interval) => ({
                      value: Number(interval),
                      label: SLOT_INTERVAL_LABELS[Number(interval)],
                    }))}
                  />
                </CabTooltip>
              </AdditionalOption>
            )} />
        </Box>

        {user?.features.BUFFER_TIME && (
          <Box display='flex' flexDirection='column' gap={1} width='100%'>
            <Typography variant="h2"> Buffer Settings </Typography>
            <BufferTimeOption fieldName={"bufferStartMinutes"} fieldLabel={"Buffer time before meeting"} 
              control={control} textSize/>
            <BufferTimeOption fieldName={"bufferEndMinutes"} fieldLabel={"Buffer time after meeting"}
              control={control} textSize/>
          </Box>
        )}

        {!meeting.is_poll && (
          <Box display='flex' flexDirection='column' gap={1} width='100%'>
            <Typography variant="h2"> Attendee Settings </Typography>
            {additionalParticipantsOption && (
              <Controller 
                name="allowAddParticipants" 
                control={control} 
                render={({ field: { value, ref, ...field } }) =>
                  <AdditionalOption>
                    <Box display='flex' gap={.5} alignItems={'center'}>
                      <FormLabel> Respondents can add attendees </FormLabel>
                      <InfoToolTip 
                        message="Allow additional attendees to be added to the meeting using the 
                      Cabinet scheduling link."
                      />
                    </Box>
                    <CabSwitch
                      {...field}
                      checked={value}
                      sx={{ margin: 0 }}
                    />
                  </AdditionalOption>
                }
              />
            )}
            <Controller 
              name="allowRescheduleCancel" 
              control={control} render={({ field: { value, ref, ...field } }) =>
                <AdditionalOption>
                  <Box display='flex' gap={.5} alignItems={'center'}>
                    <FormLabel>Attendees can reschedule/cancel</FormLabel>
                    <InfoToolTip 
                      message="Include links in the meeting description to allow attendees to 
                    reschedule/cancel the meeting on their own based on the times originally selected."
                    />
                  </Box>
                  <CabSwitch
                    {...field}
                    checked={value}
                    sx={{ margin: 0 }}
                  />
                </AdditionalOption>
              }
            />

            {user?.features.PARTICIPANT_REMINDERS && (
              <>
                <Controller name="sendParticipantReminders" control={control}
                  render={({ field: { ref, ...field } }) => (
                    <AdditionalOption>
                      <Box display='flex' gap={.5} alignItems={'center'}>
                        <FormLabel>Send reminder emails</FormLabel>
                        <InfoToolTip 
                          message="Send email reminders to all attendees of the meeting before it starts."
                        />
                      </Box>
                      <CabSwitch
                        {...field}
                        checked={field.value}
                        sx={{ margin: 0 }}
                      />
                    </AdditionalOption>
                  )} />

                <DependentController name="participantReminderMinutes" control={control}
                  dependencies={['sendParticipantReminders']}
                  render={({ field: { ref, ...field }, deps: [sendReminders] }) => (
                    sendReminders ? (
                      <AdditionalOption>
                        <Box display='flex' gap={.5} alignItems={'center'}>
                          <FormLabel sx={{lineHeight: 1}}>Email reminder notice time</FormLabel>
                          <InfoToolTip 
                            message="Time before the meeting starts that reminders should be sent"
                          />
                        </Box>
                        <CabDurationUnitPicker
                          {...field}
                          durationMinutes={field.value as number}
                          textSize
                        />
                      </AdditionalOption>
                    ) : <></>
                  )} />
              </>
            )}
          </Box>
        )}

        {!meeting.is_poll && (
          <Box display='flex' flexDirection='column' gap={1} width='100%'>
            <Typography variant="h2"> Text Settings </Typography>
            <AdditionalOption>
              <Box display='flex' gap={.5} alignItems={'center'}>
                <FormLabel>Condense times</FormLabel>
                <InfoToolTip 
                  message="Display the total free time available for the meeting 
                vs. each available start - end interval."
                />
              </Box>
              <CabSwitch
                checked={mergeSlots}
                onChange={onToggleMergeSlots}
                sx={{ margin: 0 }}
              />
            </AdditionalOption>
            <AdditionalOption>
              <Box display='flex' gap={.5} alignItems={'center'}>
                <FormLabel>Include links in times</FormLabel>
                <InfoToolTip 
                  message="Add hyperlinks to your meeting times that will direct respondents to the 
                Cabinet booking page for the exact date/time they select."
                />
              </Box>
              <CabSwitch
                checked={showTimesWithLinks}
                onChange={onToggleShowTimesWithLinks}
                sx={{ margin: 0 }}
              />
            </AdditionalOption>
            {endDate && setEndDate && (
              <AdditionalOption>
                <Box display='flex' gap={.5} alignItems={'center'}>
                  <FormLabel>Show availability until</FormLabel>
                  <InfoToolTip 
                    message="Show only the meeting times before this date."
                  />
                </Box>
                <UserPrefCabDatePicker
                  futureOnly
                  value={endDate || ''}
                  onChange={(v) => setEndDate(v || '')}
                  size='small'
                  sx={{width: '90px'}}
                />
              </AdditionalOption>
            )}
          </Box>
        )}
        {meeting.is_poll && (
          <Box display='flex' flexDirection='column' gap={1} width='100%'>
            <Typography variant="h2"> Poll Settings </Typography>
            {hasAttendeeListOptions && (
              <>
                <Controller 
                  name="enablePublicAttendeeList" 
                  control={control} 
                  render={({ field: { value, ref, ...field } }) =>
                    <AdditionalOption>
                      <Box display='flex' gap={.5} alignItems={'center'}>
                        <FormLabel>Allow voters to select from the Attendee List</FormLabel>
                        <InfoToolTip 
                          message="Enable your voters to choose from the list of pre-registered attendees."
                        />
                      </Box>

                      <Box display="flex" gap={1}>
                        {externalParticipantLength === 0 && value && (
                          <CabTooltip 
                            title="There are no attendees currently registered for this meeting."
                            wrapWithSpan
                          >
                            <CabIcon Icon={IoWarningOutline} sx={{fontSize: '20px', color: 'orange'}}/>
                          </CabTooltip>
                        )}

                        <CabSwitch
                          {...field}
                          checked={value}
                          onChange={(e, v) => handleAllowVotersToSelectFromList(v)}
                          sx={{ margin: 0 }}
                        />
                      </Box>
                    </AdditionalOption>
                  }
                />
                <Controller
                  name="allowAddParticipants"
                  control={control}
                  render={({ field: { value, ref, ...field } }) =>
                    <CabTooltip
                      title={!enablePublicAttendeeList
                        ? 'Voters cannot add additional attendees unless Attendee List is enabled'
                        : ''} 
                      wrapWithSpan
                    >
                      <AdditionalOption>
                        <Box display='flex' gap={.5} alignItems={'center'}>
                          <FormLabel disabled={!enablePublicAttendeeList}>
                            Allow voters to add additional attendees
                          </FormLabel>
                          <InfoToolTip message={!enablePublicAttendeeList
                            ? ''
                            : "Allow additional attendees to be added to the poll."}
                          />
                        </Box>
                        <CabSwitch
                          {...field}
                          checked={enablePublicAttendeeList ? value : false}
                          disabled={!enablePublicAttendeeList}
                          sx={{ margin: 0 }}
                        />
                      </AdditionalOption>
                    </CabTooltip>
                  }
                />
              </>
            )}
            {anonymousPollsOption &&
              <Controller 
                name="anonymizeVoterNames" 
                control={control} 
                render={({ field: { value, ref, ...field } }) =>
                  <CabTooltip
                    title={meeting.enable_public_attendee_list
                      ? 'Attendees cannot be anonymized when Attendee List is enabled'
                      : ''} 
                    wrapWithSpan
                  >
                    <AdditionalOption>
                      <Box display='flex' gap={.5} alignItems={'center'}>
                        <FormLabel disabled={meeting.enable_public_attendee_list}>
                          Anonymize attendee names
                        </FormLabel>
                        <InfoToolTip 
                          message={meeting.enable_public_attendee_list
                            ? ''
                            : "Hide from voters the names of other attendees next to the times they have selected."}
                        />
                      </Box>
                      <CabSwitch
                        {...field}
                        checked={value}
                        sx={{ margin: 0 }}
                        disabled={meeting.enable_public_attendee_list}
                      />
                    </AdditionalOption>
                  </CabTooltip>
                }
              />
            }
          </Box>
        )}
      </Box>
      <Box display='flex' justifyContent='flex-end' marginBottom={1}>
        {!meeting.is_poll && !isOneColumn && (
          <Box paddingLeft={1} marginTop={1}>
            <CabButton
              key={"preview"}
              buttonType='tertiary'
              onClick={() => setOpenPreview(true)}
              sx={{height: "100%"}}
            >
              <Box display="flex" alignItems="center">
                <Box marginRight={1}>Preview</Box>
                <CabIcon alt="Preview" Icon={IoOpenOutline} />
              </Box>
            </CabButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface BufferTimeOptionProps {
  fieldName: "bufferStartMinutes" | "bufferEndMinutes",
  fieldLabel: string,
  control: Control<BookingOptionsFormInput>
  textSize?: boolean;
}

const BufferTimeOption = ({fieldName, fieldLabel, control, textSize}: BufferTimeOptionProps) => (
  <Controller name={fieldName} control={control} render={({ field: { ref, ...field } }) => (
    <AdditionalOption>
      <Box display='flex' gap={.5} alignItems={'center'}>
        <FormLabel sx={{lineHeight: 1}}>{fieldLabel}</FormLabel>
        <InfoToolTip 
          message={`Buffer Time will reserve time before and/or after a meeting to account for 
              things like travel time, or just to avoid a back-to-back booking.`}
        />
      </Box>
      <Box display='flex' alignItems='center'>
        <CabDurationUnitPicker
          {...field}
          durationMinutes={field.value}
          textSize={textSize}
        />
      </Box>
    </AdditionalOption>
  )} />
);

const AdditionalOption = styled(FormLabel, { label: 'AdditionalOption' })(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}));

const areEqual = (prev: BookingOptionsProps, next: BookingOptionsProps) => {
  return isEqual(prev, next);
};

export default memo(BookingOptionsPanel, areEqual);

