import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  actions, BookingSlot, DisplayCalendarsDict, GlobalModalComponentName, Meeting,  RootState, ThunkDispatchType 
} from "../../../store";
import {
  allTimeZones, getAvailableCalendars, getBookingLinkUrl, getCalendarsDisplayed, getFormattedHyperlinkSlots,
  getFormattedSlots, getLocalTimeZone, leaderHasCalendars, mergeBookingSlots, TimeZone
} from "../../../utils/scheduleUtils";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../constants";
import { 
  fetchCalendars, fetchMeetingRooms, fetchParticipantAutocompleteOptions, fetchPresetLocations,
} from "../../../store/schedule/actions";
import { DateTime } from "luxon";
import { useDebouncedCallback } from "use-debounce";
import ScheduleShareModal, { ScheduleShareModalProps } from "./ScheduleShareModal";
import { uniq } from "lodash-es";

export type ScheduleShareModalContainerProps = {
  onModalClose: () => void;
  modalOpen: boolean;
  meetingId: number;
  showEdit?: boolean;
};

const ScheduleShareModalContainer = ({
  onModalClose, modalOpen, meetingId, showEdit
}: ScheduleShareModalContainerProps): ReactElement | null => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const meetingSlots = useSelector((state: RootState) => state.schedule.meetingSlots);
  const zoomSettings = useSelector((state: RootState) => state.schedule.zoomSettings);
  const meetingRooms = useSelector((state: RootState) => state.schedule.meetingRooms);
  const participantAutoCompleteOptions = useSelector((state: RootState) => (
    state.schedule.participantAutoCompletOptions
  ));
  const presetLocations = useSelector((state: RootState) => state.schedule.presetLocations);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);

  const currentMeeting = meetings[meetingId] as Meeting | undefined;
  const currentMeetingSlots = meetingSlots.filter(slot => slot.meeting === meetingId);

  const timezone = useMemo(
    () => currentMeeting?.copy_tz || currentMeeting?.calendar_tz || null, 
    [currentMeeting?.calendar_tz, currentMeeting?.copy_tz]
  );

  const defaultAdditionalTimezones = useMemo(
    () => currentMeeting?.secondary_tz 
      || [currentMeeting?.calendar_tz || null] 
      || [getLocalTimeZone()?.name || null] 
      || [], 
    [currentMeeting?.calendar_tz, currentMeeting?.secondary_tz]
  );

  const additionalCopyTimezones: string[] | null = useMemo(
    () => currentMeeting?.secondary_copy_tz || null, 
    [currentMeeting?.secondary_copy_tz]
  );
  
  const [displayCalendars, setDisplayCalendars] = useState<DisplayCalendarsDict>({});
  const [endDate, setEndDate] = useState<DateTime>(DateTime.now().plus({months: 1}));
  const [loadingAdditionalTimezone, setLoadingAdditionalTimezone] = useState(false);
  const [bookingSlots, setBookingSlots] = useState<BookingSlot[]>([]);
  const [unavailableSlots, setUnavailableSlots] = useState<BookingSlot[]>([]);
  const [mergeSlots, setMergeSlots] = useState(schedulingPrefs.user_prefs?.default_condense_slots || false);
  const [loadingTimes, setLoadingTimes] = useState(true);
  
  useEffect(() => {
    dispatch(fetchMeetingRooms());
    dispatch(fetchPresetLocations());
    dispatch(fetchCalendars());
  }, [dispatch]);

  const handleSetSlots = useCallback(async (externalId: string, slotsEnd: DateTime) => {
    setLoadingTimes(true);
    const res = await dispatch(actions.schedule.fetchMeetingExternal(
      externalId, undefined, DateTime.now(), slotsEnd
    ));
    if ('status' in res) {
      setBookingSlots(res.booking_slots);
      setUnavailableSlots(res.conflict_slots);
    }
    setLoadingTimes(false);
  }, [dispatch]);

  const handleSetSlotsDebounced = useDebouncedCallback((externalId: string, slotsEnd: DateTime) => {
    handleSetSlots(externalId, slotsEnd);
  }, 1000);

  useEffect(() => {
    if (currentMeeting?.external_id && modalOpen) {
      // current backend seems to grab one day past the given end
      const slotsEndDate = currentMeeting.recurring_times
        ? endDate.minus({day: 1})
        : (
          currentMeetingSlots.length > 0 ? DateTime.fromISO(
            currentMeetingSlots.sort((slotA, slotB) => slotB.end_date.localeCompare(slotA.end_date))[0].end_date
          ) : DateTime.now()
        );
      handleSetSlotsDebounced(currentMeeting.external_id, slotsEndDate);
    }
    // only want to trigger if setting that effect the meeting slots change
    // we don't want these to cause changes to slot list right now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentMeetingSlots), JSON.stringify(currentMeeting?.recurring_times), currentMeeting?.external_id,
    currentMeeting?.start_delay_minutes, currentMeeting?.prevent_conflict, currentMeeting?.slot_time_interval, endDate, 
    handleSetSlotsDebounced, modalOpen, currentMeeting?.buffer_start_minutes, currentMeeting?.buffer_end_minutes]);

  useEffect(() => {
    const availableAssociations = getAvailableCalendars(calendars, user, leaders.leaders);
    const hasAssociations = leaderHasCalendars(availableAssociations, currentMeeting?.leaders || []);

    if (hasAssociations && user) {
      const newCalendarsDisplayed = getCalendarsDisplayed(
        availableAssociations,
        uniq([user.profile.user_leader, ...(currentMeeting?.leaders || [])]),
        Object.values(currentMeeting?.participants || {}).filter(
          partItr => !!partItr.calendar_access && partItr.view_calendar
        ).map(partItr => partItr.calendar_access) as number[],
        displayCalendars, leaders.leaders,
      );
      setDisplayCalendars(newCalendarsDisplayed);  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars, JSON.stringify(currentMeeting?.participants),
    user, currentMeeting?.leaders]);

  const meetingAttendees = useMemo<ScheduleShareModalProps['attendees']>(() => (
    Object.values(currentMeeting?.participants || {}).filter(p => p.meeting === meetingId)
  ), [currentMeeting?.participants, meetingId]);

  const mergedSlots = useMemo (
    () => mergeBookingSlots(bookingSlots, currentMeeting?.duration_minutes || 30),
    [bookingSlots, currentMeeting?.duration_minutes]
  );
  

  // NOTE THIS WAS CREATED BECAUSE MEETING SLOTS CAN BE FAR IN THE FUTURE
  // FOR A REUSEABLE AND ON INITIAL LOAD THE END DATE IS SET TO A MONTH FROM NOW
  // WHICH MAY NOT BE THE CORRECT END DATE FOR VISUALIZING THE SLOTS
  useEffect(() => {
    const sortedSlots = currentMeetingSlots.filter(
      slot => DateTime.fromISO(slot.start_date).toMillis() > DateTime.now().toMillis()
    ).sort(
      (slotA, slotB) => DateTime.fromISO(slotA.start_date).toMillis() - DateTime.fromISO(slotB.start_date).toMillis() 
    );
    
    if (sortedSlots.length > 0) {
      const correctEndDate = DateTime.fromISO(sortedSlots[0].start_date).plus({month: 1});
      if (endDate.toMillis() < correctEndDate.toMillis()) {
        setEndDate(correctEndDate);
      }
    }
  // We are not includig endDate in the dependency array because we don't want to trigger this effect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentMeetingSlots)]);

  const futureMeetingSlots = useMemo (
    () => currentMeetingSlots.filter(slot => DateTime.fromISO(slot.start_date) > DateTime.now()
      .plus({minutes: currentMeeting?.start_delay_minutes})),
    [currentMeetingSlots, currentMeeting?.start_delay_minutes]
  );

  const formattedSlots = getFormattedSlots(
    mergeSlots ? mergedSlots : bookingSlots,
    allTimeZones.find((zone: TimeZone) => zone.name === timezone),
    additionalCopyTimezones);

  const hyperlinkedSlots = currentMeeting ? getFormattedHyperlinkSlots(
    mergeSlots ? mergedSlots : bookingSlots,
    getBookingLinkUrl(currentMeeting),
    allTimeZones.find((zone: TimeZone) => zone.name === timezone),
    meetingId, additionalCopyTimezones
  ) : '';
    
  const handleTimezoneChange = (value: string) => {
    handleUpdateMeeting({copy_tz: value});
  };

  const handleToggleMergeSlots = () => {
    setMergeSlots(!mergeSlots);
  };
  
  const handleAdditionalTimezoneChange = async (value: string[] | null) => {
    setLoadingAdditionalTimezone(true);
    await handleUpdateMeeting({secondary_copy_tz: value});
    setLoadingAdditionalTimezone(false);
  };

  const handleUpdateMeeting = async (meetingPartial: Partial<Meeting>) => {
    if (!currentMeeting) return;
    await dispatch(actions.schedule.updateMeeting({ ...meetingPartial, id: currentMeeting.id }, []));
  };

  const handleEditMeeting = () => {
    onModalClose();
    navigate(`${PAGE_URL.SCHEDULE}/${meetingId}/`);
  };

  const handleAllowVotersToSelectFromList = async (allow: boolean) => {
    if (allow !== currentMeeting?.enable_public_attendee_list) {
      await handleUpdateMeeting({
        enable_public_attendee_list: allow,
        show_voter_names: allow || undefined,
        allow_add_participants: currentMeeting?.is_poll && allow ? undefined : false,
      });
    }
  };

  const handleDeleteAttendee = async (attendeeId: number) => {
    if (!currentMeeting) return;
    await dispatch(actions.schedule.deleteMeetingParticipant(attendeeId, currentMeeting.id));
  };

  const handleAddAttendee: ScheduleShareModalProps['onAddAttendee'] = async (attendee) => {
    await dispatch(actions.schedule.createMeetingParticipant({
      ...attendee,
      required: true,
      meeting: meetingId,
    }));
  };

  const handleUpdateAttendee: ScheduleShareModalProps['onUpdateAttendee'] = async (attendee) => {
    if (!currentMeeting) return;
    await dispatch(actions.schedule.updateMeetingParticipant({
      ...attendee, 
      no_times_comment: null,
    }, currentMeeting.id));
  };

  const handleFetchAutoCompleteOptions = async (value: string) => {
    await dispatch(fetchParticipantAutocompleteOptions(value));
  };

  const handleSetEndDate = (date: string) => {
    setEndDate(DateTime.fromISO(date).set({hour: 0, minute: 0, second: 0, millisecond: 0}));
  };
  
  const isOwner = !!(user && currentMeeting && user.id === currentMeeting.create_user?.id);

  return (
    <ScheduleShareModal
      calendars={calendars}
      meeting={currentMeeting || null}
      user={user}
      zoomSettings={zoomSettings}
      leaders={leaders}
      modalOpen={modalOpen}
      onModalClose={onModalClose}
      displayedCalendars={displayCalendars}
      formattedSlots={formattedSlots}
      hyperlinkedSlots={hyperlinkedSlots}
      unavailableSlots={unavailableSlots}
      templateTimezone={timezone}
      onTemplateTimezoneSelect={handleTimezoneChange}
      endDate={currentMeeting?.recurring_times ? endDate.toISO() || "" : undefined}
      setEndDate={currentMeeting?.recurring_times ? handleSetEndDate : undefined}
      onChangeValues={handleUpdateMeeting}    
      isOwner={isOwner}
      meetingRooms={meetingRooms}
      attendees={meetingAttendees}
      onAllowVotersToSelectFromList={handleAllowVotersToSelectFromList}
      onDeleteAttendee={handleDeleteAttendee}
      onAddAttendee={handleAddAttendee}
      onUpdateAttendee={handleUpdateAttendee}
      participantAutocompleteOptions={participantAutoCompleteOptions}
      fetchAutocompleteOptions={handleFetchAutoCompleteOptions}
      onEdit={handleEditMeeting}
      showEdit={showEdit}
      presetLocations={presetLocations}
      additionalCopyTimezones={additionalCopyTimezones || []}
      onAdditionalTimezoneChange={handleAdditionalTimezoneChange}
      loadingAdditionalTimezone={loadingAdditionalTimezone}
      defaultAdditionalTimezones={defaultAdditionalTimezones}
      mergeSlots={mergeSlots}
      handleToggleMergeSlots={handleToggleMergeSlots}
      loadingTimes={loadingTimes}
      openUpgradeModal={() => dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO))}
      currentMeetingSlots={currentMeetingSlots}
      futureMeetingSlots={futureMeetingSlots}
      meetingSlotsOutOfRange={futureMeetingSlots.length > 0 && 
        endDate.toMillis() < DateTime.fromISO(futureMeetingSlots[0].start_date).toMillis()
      }
      timezone={timezone}
    />
  );
}; 

export default ScheduleShareModalContainer;
